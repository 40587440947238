// Text Colors
$sub-header-bg-color: #ffffff;
$theme-color: #9100da;
$primary-text-color: #000000;
$secondary-text-color: #333333;
$header-color: #090909;
$greyish-brown: #595959;
$dark-sky-blue: #4caad1;

$good: #00ffc1;
$moderate: #99e82d;
$low: #ffb805;
$bad: #fe2d04;
$default: #b8bec7;

$white: #fff;
$black: #000;
$light-gray: #d6d6d6;
$baby-blue: #a5e3ff;
$blue: #2898c6;
$light-navy: #134d97;

// Bandwidth
$usage: #9100da;
$plan-max: #6864e9;
$max-offered: rgba(176, 177, 180, 0.54);

// Devices
$devices-icon-active: #9100da;
$devices-icon-inactive: #96a1b1;


// Font Family
$primary-font: Apercu-Pro-Regular;
$primary-font-light: Apercu-Pro-Light;
$primary-font-mono: Apercu-Pro-Mono;
$secondary-font: Arial;

// Background Color
$top-header: #20252b;
$sub-header: #2898c6;
$third-header: #3b4047;
$user-info-panel: #f0f0f0;
$nav: #244063;
$dark-slate-blue: #244063;
$dark-sky-blue: #4caad1;
$zambezi: #5f5f5f;
$darkgrey: #a7a7a7;
$whitesmoke: #f4f4f4;
$whisper: #e4e4e4;

// Z-index
$z-index-latency-thead: 9;
$z-index-search-clear: 99;
$z-index-header-menu: 999;
$z-index-spinner: 99999;