.design-studio-container {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
    padding: 10px;
}

.design-studio-bar {
  flex: 1;
 
  padding: 2px;
  display: flex;
  border: 1px solid;
  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
  }
  
  .custom-file-upload {
    position: relative;
    border: 1px solid;
    border-radius:10px;
    font-size: 14px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 5px;
    text-align: center;
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
  }
  
  .custom-file-upload:after {
    content: "";
    background: #f1f1f1;
    display: block;
    position: absolute;

    opacity: 0;
    transition: all 0.8s
  }
  
  .custom-file-upload:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
  }

  /* toggle in label designing */
  .toggle {
    position : relative ;
    display : inline-block;
    width : 100px;
    height : 52px;
    background-color: red;
    border-radius: 30px;
    border: 2px solid gray;
  }
        
  /* After slide changes */
  .toggle:after {
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: gray;
      top: 1px;
      left: 1px;
      transition:  all 0.5s;
  }
        

  /* Checkbox checked effect */
  .checkbox:checked + .toggle::after {
      left : 49px;
  }
        
  /* Checkbox checked toggle label bg color */
  .checkbox:checked + .toggle {
      background-color: green;
  }
        
  /* Checkbox vanished */
  .checkbox {
      display : none;
  }
};