.campaign-div:hover {
  cursor: pointer;
}

.new-campaign-form {}

@media (min-width: 750px) and (max-width: 1130px) {
  .campaign-type-container {
    .text-container {
      padding-left: 3rem;
      padding-right: 1.2rem;
    }
  }
}

@media (max-width: 580px) {
  .campaign-type-container {
    .text-container {
      padding: 1rem 1.5rem;
    }
  }
}