.new-campaign-container {
  input {
    padding: 8px 12px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid lightgray;
  }
}

.file-upload-container {
  box-sizing: border-box;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 9px;
}

label.required:after {
  content: " *";
  color: red;
}

.react-datetime-container {
  display: flex;
  align-items: center;

  .react-datetime-picker__wrapper {
    min-height: 38px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    margin-top: 1.6rem;

  }
}

@media only screen and (max-width: 994px) {
  .react-datetime-picker__wrapper {
    margin-bottom: 1.6rem;
  }
}