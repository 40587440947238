.no-data-found {
  text-align: center;
  padding-top: 150px;

  h2 {
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.9px;
    color: $greyish-brown;
  }

  img {
    height: 146px;
    width: 146px;
    margin: 31px 0;
  }

}