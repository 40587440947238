@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.drawer {
  -webkit-animation: slide-in 0.2s ease-in;
  -moz-animation: slide-in 0.2s ease-in; }

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%); }
  100% {
    opacity: 1;
    -moz-transform: translateX(0); } }

.viewer .navbar-toggler {
  display: none; }

.drawer .navbar-toggler {
  display: none; }

@media (max-width: 768px) {
  .drawer {
    display: none; }
  .viewer .navbar-toggler {
    display: inherit; }
  .switchview .drawer {
    display: block !important;
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%; }
    .switchview .drawer .navbar-toggler {
      display: inherit; }
  .switchview .viewer {
    display: none !important; } }

.pane {
  height: calc(100% - 110px);
  overflow: hidden;
  overflow-y: auto; }

.sidelistx,
.scrollArea {
  height: calc(100% - 54px);
  overflow: hidden;
  overflow-y: auto; }

.downshift-inner {
  height: calc(100% - 56px); }

.messageList {
  padding: 1em; }

.loader {
  color: purple;
  width: 100%; }

.chatMsg {
  min-width: 25%;
  max-width: 75%; }

.list-group-flush .list-group-item:first-child {
  border-top: 0; }

.list-group-flush h3 {
  margin-bottom: 0; }

.file-input-holder {
  width: 42px;
  position: relative;
  display: flex !important; }
  .file-input-holder input[type='file'] {
    width: 42px;
    height: 38px;
    z-index: 2;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    position: relative; }
  .file-input-holder label {
    position: absolute;
    top: 0;
    left: 0; }

.file-placeholder {
  height: 200px; }

.msg-image {
  max-width: 100%;
  max-height: 300px; }

@media (max-width: 768px) {
  .msg-image {
    max-height: 200px !important; } }


    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    
    .drawer {
      -webkit-animation: slide-in 0.2s ease-in;
      -moz-animation: slide-in 0.2s ease-in;
    }
    
    @-webkit-keyframes slide-in {
      0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
      }
      100% {
        opacity: 1;
        -webkit-transform: translateX(0);
      }
    }
    @-moz-keyframes slide-in {
      0% {
        opacity: 0;
        -moz-transform: translateX(-100%);
      }
      100% {
        opacity: 1;
        -moz-transform: translateX(0);
      }
    }
    
    .viewer {
      .navbar-toggler {
        display: none;
      }
    }
    
    .drawer {
      .navbar-toggler {
        display: none;
      }
    }
    
    @media (max-width: 768px) {
      .drawer {
        display: none;
      }
    
      .viewer {
        .navbar-toggler {
          display: inherit;
        }
      }
    
      .switchview {
        .drawer {
          display: block !important;
          width: 100% !important;
          max-width: 100% !important;
          flex: 0 0 100%;
          .navbar-toggler {
            display: inherit;
          }
        }
        .viewer {
          display: none !important;
        }
      }
    }
    
    .pane {
      height: calc(100% - 110px);
      overflow: hidden;
      overflow-y: auto;
    }
    
    .sidelistx,
    .scrollArea {
      height: calc(100% - 54px);
      overflow: hidden;
      overflow-y: auto;
    }
    .downshift-inner {
      height: calc(100% - 56px);
    }
    
    .messageList {
      padding: 1em;
    }
    
    .loader {
      color: purple;
      width: 100%;
    }
    
    .chatMsg {
      min-width: 25%;
      max-width: 75%;
    }
    
    .list-group-flush .list-group-item:first-child {
      border-top: 0;
    }
    
    .list-group-flush h3 {
      margin-bottom: 0;
    }
    
    .file-input-holder {
      width: 42px;
      position: relative;
      display: flex !important;
    
      input[type='file'] {
        width: 42px;
        height: 38px;
        z-index: 2;
        margin: 0;
        opacity: 0;
        overflow: hidden;
        position: relative;
      }
    
      label {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    
    .file-placeholder {
      height: 200px;
    }
    
    .msg-image {
      max-width: 100%;
      max-height: 300px;
    }
    
    @media (max-width: 768px) {
      .msg-image {
        max-height: 200px !important;
      }
    }
    
