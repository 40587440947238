  .campaign-type-container {
    background: #FFFFFF;
    border: 1px solid #3F3F3F;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 34px 0 20px;

    .icon-holder {
      display: flex;
      justify-content: center;
      border: 1px solid #3F3F3F;
      background-color: #3F3F3F;
      width: 3%;
      height: 3%;
      border-radius: 50%;

      span {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
        color: white;
      }
    }

    span.msg {
      padding: 0 20px;
      font-size: 16px;
    }
  }