@font-face {
  font-family: Apercu-Pro-Light;
  src: url("./fonts/Apercu-Pro-Light.otf") format("opentype");
}

@font-face {
  font-family: Apercu-Pro-Mono;
  src: url("./fonts/Apercu-Pro-Mono.otf") format("opentype");
}

@font-face {
  font-family: Apercu-Pro-Regular;
  src: url("./fonts/Apercu-Pro-Regular.otf") format("opentype");
}

@import './variables.scss';

// Styles
body {
  font-family: $primary-font;
  color: $primary-text-color;
  background-color: $white;
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  overflow-x: hidden;
}

.border-bottom {
  border-bottom: 1px solid #020102 !important;
  ;
}



// Media Queries
@media (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
}
















// Importing all the scss files at the END
@import "./imports.scss";