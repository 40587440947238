  .new-campaign-col {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .btn {
      margin: 10px;
    }
  }

  .search-col {
    .search-component {
      display: flex;
      height: 38px;

      .react-select-container {
        width: 20%;

        .react-select__menu-list {
          padding: 0;
          margin-top: -2px;
          border-radius: 5px;
        }

        .react-select__indicator-separator {
          display: none;
        }

        .react-select__control {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          background-color: rgba(255, 255, 255, 0.1);
          cursor: pointer;

          .css-xp4uvy {
            color: $white;
            font-weight: 400;
          }

          .material-icons {
            color: $white;
          }
        }

        .react-select__control--is-focused {
          background-color: rgba(255, 255, 255, 0.25);
        }
      }

      .search-container {
        width: 80%;
        display: flex;

        .input-field {
          width: 90%;
        }

        input:focus {
          background-color: #f3f1d5;
        }

        .button-field {
          width: 10%;
          background-image: linear-gradient(to bottom, #565529, #4c4a2a);
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          position: relative;
          cursor: pointer;

          .material-icons {
            position: absolute;
            top: 8px;
            right: 8px;
            color: $white;
          }
        }

        button:focus {
          background-image: linear-gradient(to bottom, #d5c922, #b09d25);
        }

        .search-clear {
          display: flex;
          height: 23px;
          width: 23px;
          justify-content: center;
          position: absolute;
          top: 23px;
          right: 82px;
          align-items: center;
          background: #cecece;
          border-radius: 50%;
          color: $white;
          z-index: $z-index-search-clear;
          cursor: pointer;

          .material-icons {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .my-modal {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 300px;
    height: 300px;
    box-shadow: 0 5px 10px 2px rgba(195, 192, 192, .5);
    padding: 20px;
    text-align: center;
  }

  .modal-close {
    position: absolute;
    right: 6px;
    top: 9px;
    cursor: pointer;
    border: none;
    font-size: 20px;
  }

  .modal-toggle-button {
    cursor: pointer;
    background-color: green;
    border: none;
    color: white;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 20px;
  }

  .modal-toggle-button:focus {
    outline: none;
  }