.new-user-form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  label {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    width: 400px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  input {
    height: 40px;
    flex: 0 0 200px;
    margin-left: 10px;
  }

  .datetime-container {
    display: flex;
    align-items: center;

    .react-datetime-picker__wrapper {
      min-height: 38px;
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      margin-top: 1.6rem;

    }
  }
}
.subheader-container {
  .subheader-row {
    display:flex;
    align-items: center;
    margin-bottom: 10px;
    gap:10px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      white-space: nowrap;
      section {
        padding-right:6px;
      }
      // padding-right:15px;
    }
    div.create-new-campaign {
      margin-left: auto;
    }
  }
}

.user-email-container {
  .custom-domain-icon {
    svg {
      color: #91d5ff;
    }
  }
}

